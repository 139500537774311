import React from "react"
import Title from "../Title"
import styles from "../../css/contact.module.css"
const Contact = () => {
  return (
    <section className={styles.contact}>
      <Title title="Free:" subtitle="to add your rehab" />
      <div className={styles.center}>
        <form action="https://formspree.io/xyybborp" method="POST" className={styles.form}>
          <div>
            <label htmlFor="name">contact name</label>
            <input
              type="text"
              name="name"
              id="name"
              className={styles.formControl}
              placeholder="please enter full name"
            />
          </div>
          <div>
            <label htmlFor="email">contact email</label>
            <input
              type="email"
              name="email"
              id="email"
              className={styles.formControl}
              placeholder="your email address"
            />
          </div>
          <div>
            <label htmlFor="message">message</label>
            <textarea
              name="message"
              id="message"
              rows="10"
              className={styles.formControl}
              placeholder="Enter the rehab name and any additional information so we can get the details."
            />
          </div>
          <div>
            <input
              type="submit"
              value="submit here"
              className={styles.submit}
            />
          </div>
        </form>
      </div>
    </section>
  )
}

export default Contact
